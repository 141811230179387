<script>
import Applicant from '~/models/Applicant';
import Ping from '~/models/Ping';
import ApplicantDetail from '~/pages/shared/applicants/ApplicantDetail';

export default {
  extends: ApplicantDetail,

  middleware: ['admin'],

  data: () => ({
    query: Applicant.include(
      'user',
      'occupation',
      'organization',
      'legalContact',
      'graduationType',
    ),

    pingFields: ['sent_at', 'organization_name', 'applicant_occupation_name'],
  }),

  computed: {
    pingQuery() {
      return () => new Ping()
        .include('organization', 'applicant.occupation')
        .where('applicant_id', this.$route.params.id);
    },
  },
};
</script>
